<template>
  <div class="wrapper">
    <div class="wrapper__card">
      <div class="wrapper__card__stepper">
        <div class="wrapper__card__stepper__zstepper">
          <ZStepperMobileVue
            class="show-on-mobile"
            :steppers="steppers"
            :current-stepper="stepperValue"
          />
          <z-stepper
            class="hide-on-mobile"
            :steppers="steppers"
            :value="stepperValue"
            @change="goToNextZMode($event)"
          ></z-stepper>
        </div>
        <v-form ref="form" @submit.prevent="">
          <section v-if="stepperValue === 1">
            <div class="form-content">
              <div class="form-content__title">
                <span>
                  Crie sua conexão ou escolha uma conexão previamente criada para
                  segmentar
                </span>
              </div>

              <div class="form-content__header">
                <z-select
                  v-model="dbConfig.type"
                  placeholder="Clique aqui para iniciar"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  :items="connections"
                  itemText="name"
                  itemValue="value"
                />
              </div>

              <div v-if="dbConfig.type" class="form-content__body">
                <div class="form-content__body__elements">
                  <div
                    class="form-content__body__elements__left"
                    v-if="dbConfig.type !== 'csv'"
                  >
                    <div class="title">
                      <span>
                        Preencha os campos abaixo de acordo com o arquivo que foi
                        selecionado:
                      </span>
                    </div>

                    <div class="list">
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6">
                          <div class="list__item">
                            <div class="list__item__input">
                              <z-input
                                v-model="dbConfig.host"
                                :rules="[(v) => !!v || 'Campo obrigatório']"
                                :hideDetails="true"
                                placeholder="Servidor de banco de dados"
                                type="text"
                              />
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="12" sm="12" md="6" lg="6">
                          <div class="list__item">
                            <div class="list__item__input">
                              <z-input
                                v-model="dbConfig.defaultSchema"
                                :rules="[(v) => !!v || 'Campo obrigatório']"
                                :hideDetails="true"
                                placeholder="Schema"
                                type="text"
                              />
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="12" sm="12" md="6" lg="6">
                          <div class="list__item">
                            <div class="list__item__input">
                              <z-input
                                v-model="dbConfig.port"
                                :rules="[(v) => !!v || 'Campo obrigatório']"
                                :hideDetails="true"
                                placeholder="Porta"
                                type="text"
                              />
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="12" sm="12" md="6" lg="6">
                          <div class="list__item">
                            <div class="list__item__input">
                              <z-input
                                v-model="dbConfig.dbName"
                                :rules="[(v) => !!v || 'Campo obrigatório']"
                                :hideDetails="true"
                                placeholder="Nome do  banco de dados"
                                type="text"
                              />
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="12" sm="12" md="6" lg="6">
                          <div class="list__item">
                            <div class="list__item__input">
                              <z-input
                                v-model="dbConfig.user"
                                :rules="[(v) => !!v || 'Campo obrigatório']"
                                :hideDetails="true"
                                placeholder="Nome do usuário"
                                type="text"
                              />
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="12" sm="12" md="6" lg="6">
                          <div class="list__item">
                            <div class="list__item__input">
                              <z-input
                                v-model="dbConfig.pwd"
                                :rules="[(v) => !!v || 'Campo obrigatório']"
                                :hideDetails="true"
                                placeholder="Senha"
                                type="text"
                              />
                            </div>
                            <div class="list__item--action">
                              <z-btn
                                text="Testar conexão"
                                color="primary"
                                :is-loading="loadingIntegration"
                                @click="integrationTest"
                              />
                              <z-btn
                                :isLoading="loadingConnect"
                                text="Conectar"
                                primary
                                color="primary"
                                @click="conect"
                              />
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>

                  <div class="form-content__body__elements__left" v-else>
                    <div class="title">
                      <span>
                        Utilize o botão abaixo para selecionar o arquivo desejado:
                      </span>
                    </div>

                    <div class="list" style="padding-top: 10px">
                      <div class="list__item">
                        <div class="list__item__input">
                          <z-btn
                            :isLoading="loadingConnect"
                            text="Escolher arquivo"
                            primary
                            color="primary"
                            @click="openFilePicker()"
                          />
                          <span v-if="selectedCSVFile" style="padding-left: 10px">{{
                            selectedCSVFile.name
                          }}</span>
                          <input
                            @change="previewFiles"
                            type="file"
                            ref="file"
                            accept=".csv"
                            style="display: none"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- <div v-if="selectedCSVFile">
                      <span>Selecione o campo separador do CSV</span>
                      <v-row>
                        <v-col cols="4">
                          <z-select
                            v-model="csvSeparator"
                            placeholder="Selecione o campo separador do CSV"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            :items="csv_separators"
                            itemText="name"
                            itemValue="value"
                          />
                        </v-col>
                      </v-row>
                      </div> -->
                  </div>

                  <div
                    v-if="dbConfig.type && tables.length"
                    class="form-content__body__elements__right"
                  >
                    <div class="title">
                      <span> Selecione a tabela que será utilizada: </span>
                    </div>
                    <div class="list">
                      <div class="list__item">
                        <div class="list__item__input">
                          <z-autocomplete
                            v-model="choosenTable"
                            :items="tables"
                            itemText="table"
                            :hideDetails="true"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            placeholder="Tabelas"
                            return-object
                            @change="changeTable"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="form-content__body__elements__right">
                    <div class="title">
                      <span></span>
                    </div>
                    <div class="list">
                      <div class="list__item">
                        <div class="list__item__input"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- <section v-if="stepperValue === 1">
            <div class="form-content">
              <div class="form-content__title">
                <span>
                  Crie sua conexão ou escolha uma conexão previamente criada para
                  segmentar
                </span>
              </div>

              <div class="form-content__header">
                <z-select
                  v-model="dbConfig.type"
                  placeholder="Clique aqui para iniciar"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  :items="connections"
                  itemText="name"
                  itemValue="value"
                />
              </div>

              <div v-if="dbConfig.type" class="form-content__body">
                <div class="form-content__body__elements">
                  <div
                    class="form-content__body__elements__left"
                    v-if="dbConfig.type !== 'csv'"
                  >
                    <div class="title">
                      <span>
                        Preencha os campos abaixo de acordo com o arquivo que foi
                        selecionado:
                      </span>
                    </div>

                    <div class="list">
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="6">
                          <div class="list__item">
                            <div class="list__item__input">
                              <z-input
                                v-model="dbConfig.host"
                                :rules="[(v) => !!v || 'Campo obrigatório']"
                                :hideDetails="true"
                                placeholder="Servidor de banco de dados"
                                type="text"
                              />
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="12" sm="12" md="6" lg="6">
                          <div class="list__item">
                            <div class="list__item__input">
                              <z-input
                                v-model="dbConfig.defaultSchema"
                                :rules="[(v) => !!v || 'Campo obrigatório']"
                                :hideDetails="true"
                                placeholder="Schema"
                                type="text"
                              />
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="12" sm="12" md="6" lg="6">
                          <div class="list__item">
                            <div class="list__item__input">
                              <z-input
                                v-model="dbConfig.port"
                                :rules="[(v) => !!v || 'Campo obrigatório']"
                                :hideDetails="true"
                                placeholder="Porta"
                                type="text"
                              />
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="12" sm="12" md="6" lg="6">
                          <div class="list__item">
                            <div class="list__item__input">
                              <z-input
                                v-model="dbConfig.dbName"
                                :rules="[(v) => !!v || 'Campo obrigatório']"
                                :hideDetails="true"
                                placeholder="Nome do  banco de dados"
                                type="text"
                              />
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="12" sm="12" md="6" lg="6">
                          <div class="list__item">
                            <div class="list__item__input">
                              <z-input
                                v-model="dbConfig.user"
                                :rules="[(v) => !!v || 'Campo obrigatório']"
                                :hideDetails="true"
                                placeholder="Nome do usuário"
                                type="text"
                              />
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="12" sm="12" md="6" lg="6">
                          <div class="list__item">
                            <div class="list__item__input">
                              <z-input
                                v-model="dbConfig.pwd"
                                :rules="[(v) => !!v || 'Campo obrigatório']"
                                :hideDetails="true"
                                placeholder="Senha"
                                type="text"
                              />
                            </div>
                            <div class="list__item--action">
                              <z-btn
                                text="Testar conexão"
                                color="primary"
                                :is-loading="loadingIntegration"
                                @click="integrationTest"
                              />
                              <z-btn
                                :isLoading="loadingConnect"
                                text="Conectar"
                                primary
                                color="primary"
                                @click="conect"
                              />
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>

                  <div class="form-content__body__elements__left" v-else>
                    <div class="title">
                      <span>
                        Utilize o botão abaixo para selecionar o arquivo desejado:
                      </span>
                    </div>

                    <div class="list" style="padding-top: 10px">
                      <div class="list__item">
                        <div class="list__item__input">
                          <z-btn
                            :isLoading="loadingConnect"
                            text="Escolher arquivo"
                            primary
                            color="primary"
                            @click="openFilePicker()"
                          />
                          <span v-if="selectedCSVFile" style="padding-left: 10px">{{
                            selectedCSVFile.name
                          }}</span>
                          <input
                            @change="previewFiles"
                            type="file"
                            ref="file"
                            accept=".csv"
                            style="display: none"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="dbConfig.type && tables.length"
                    class="form-content__body__elements__right"
                  >
                    <div class="title">
                      <span> Selecione a tabela que será utilizada: </span>
                    </div>
                    <div class="list">
                      <div class="list__item">
                        <div class="list__item__input">
                          <z-autocomplete
                            v-model="choosenTable"
                            :items="tables"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            itemText="table"
                            :hideDetails="true"
                            placeholder="Tabelas"
                            return-object
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-content__body__elements__right">
                    <div class="title">
                      <span></span>
                    </div>
                    <div class="list">
                      <div class="list__item">
                        <div class="list__item__input"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> -->

          <section v-if="stepperValue === 2">
            <div class="card-item-step step-3">
              <div class="card-item-step__title">
                <span> Mapeamento de Dados </span>
              </div>
              <div class="card-item-step__content">
                <div class="table">
                  <div class="table__header">
                    <div class="table__header__line">
                      <div><span class="text-header">Nome do campo</span></div>
                      <div><span class="text-header">Coluna</span></div>
                    </div>
                  </div>
                  <v-divider class="my-3"></v-divider>

                  <div class="table__body">
                    <div
                      v-for="(item, i) in dataMapperObjectFixed"
                      :key="i"
                      class="table__body__line"
                    >
                      <div>
                        <span>{{ getCampName(i) }}</span>
                      </div>
                      <div v-if="getCampName(i).toLowerCase() != 'telefone (opcional)'">
                        <z-autocomplete
                          v-if="dbConfig.type !== 'csv'"
                          v-model="dataMapperObjectFixed[i]"
                          :rules="[(v) => i !== 'id' || !!v || 'Campo obrigatório']"
                          :items="choosenTable?.fields.length ? choosenTable.fields : []"
                          :hideDetails="true"
                          itemText="name"
                        />
                        <z-autocomplete
                          v-else
                          v-model="dataMapperObjectFixed[i]"
                          :rules="[(v) => i !== 'id' || !!v || 'Campo obrigatório']"
                          :items="CSVColumns !== null ? CSVColumns : []"
                          :hideDetails="true"
                          itemText="name"
                        />
                      </div>
                      <div v-else>
                        <z-btn
                          :text="
                            !phoneCombinations.length > 0 && !phoneColumnDataType?.length
                              ? 'Configurar'
                              : 'Configurado'
                          "
                          :color="
                            !phoneCombinations.length > 0 && !phoneColumnDataType?.length
                              ? 'primary'
                              : 'success'
                          "
                          :rounded="true"
                          style="width: 100%"
                          @click="phoneDialog = true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section v-if="stepperValue === 3">
            <div class="card-item-step step-4">
              <div class="card-item-step__title">
                <span> Informações auxiliares (opcional) </span>
              </div>

              <div class="card-item-step__content">
                <div class="table">
                  <div class="table__header">
                    <div class="table__header__line">
                      <div>
                        <span class="text-header"
                          ><z-checkbox
                            :disabled="!dataMapperObjectModular.length"
                            v-model="checkAllData"
                            :hideDetails="true"
                          />
                          Selecionar todos</span
                        >
                      </div>
                      <div class="hide">
                        <span class="text-header" style="width: 200px"
                          >Nome do campo</span
                        >
                      </div>
                      <div class="hide text-center" style="width: 200px">
                        <span class="text-header">Novo nome</span>
                      </div>
                      <div class="hide text-center" style="width: 200px">
                        <span class="text-header">Tipo do campo</span>
                      </div>
                      <div class="hide text-center">
                        <span class="text-header">Formato do campo</span>
                      </div>
                    </div>
                  </div>
                  <v-divider class="my-3"></v-divider>
                  <div v-if="dataMapperObjectModular.length" class="table__body">
                    <div
                      v-for="(item, i) in dataMapperObjectModular"
                      :key="i"
                      class="table__body__line flex-line"
                    >
                      <div>
                        <z-checkbox
                          v-model="item.active"
                          :hideDetails="true"
                          @change="addOrRemoveKeyOnMapper($event, item)"
                        />
                      </div>

                      <div>
                        <span v-if="dbConfig.type !== 'csv'">{{ item.name }}</span>
                        <span v-else>{{ item.item }}</span>
                      </div>
                      <div>
                        <z-input
                          v-model="item.destiny_name"
                          placeholder="Digite aqui"
                          :hide-details="true"
                          type="text"
                        />
                      </div>
                      <div class="d-flex">
                        <z-select
                          v-if="item.active"
                          v-model="item.dataType"
                          placeholder="Tipo do campo"
                          itemText="name"
                          itemValue="value"
                          :hide-details="true"
                          :items="dataTypes"
                          :rules="[(v) => !!v || 'Campo obrigatório']"
                          type="text"
                        />
                        <z-select
                          v-else
                          v-model="item.dataType"
                          :items="dataTypes"
                          itemText="name"
                          itemValue="value"
                          placeholder="Tipo do campo"
                          :hide-details="true"
                          type="text"
                          readonly
                        />
                      </div>

                      <div style="width: 100px">
                        <z-select
                          v-if="item.dataType === 'datetime'"
                          :rules="[(v) => !!v || 'Campo obrigatório']"
                          :items="dateTimeFormats"
                          itemText="format"
                          v-model="item.format"
                          itemValue="format"
                          placeholder="Formato do campo"
                          :hide-details="true"
                        />
                        <z-select
                          v-else-if="item.dataType === 'date'"
                          :rules="[(v) => !!v || 'Campo obrigatório']"
                          :items="dateFormats"
                          itemText="format"
                          v-model="item.format"
                          itemValue="format"
                          placeholder="Formato do campo"
                          :hide-details="true"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-else><strong>Informções indisponíveis</strong></div>
                </div>
              </div>
            </div>
          </section>

          <section v-if="stepperValue === 4">
            <div class="card-item-step step-2">
              <div class="card-item-step__title">
                <span> Agregação de dados </span>
              </div>

              <div class="card-item-step__content my-3">
                <div class="card-item-step__content__information">
                  <span class="mb-2" style="display: block">
                    Escolha a tabela para fazer as regras de agregação
                  </span>

                  <div></div>

                  <v-row dense>
                    <!-- <v-col
                      cols="8"
                      sm="8"
                      md="3"
                      lg="3"
                      v-if="dbConfig.type !== 'csv'"
                    >
                      <z-autocomplete
                        v-model="choosenTable"
                        :items="tables"
                        itemText="table"
                        :hideDetails="true"
                        label="Tabelas"
                        :rules="[(v) => !!v || 'Campo obrigatório']"
                        return-object
                      />
                    </v-col> -->
                    <v-col cols="8" sm="8" md="3" lg="3">
                      <z-select
                        v-model="choosenConfiguration"
                        label="Conexão primária"
                        :rules="[(v) => !!v || 'Campo obrigatório']"
                        :items="configurationList"
                        itemText="name"
                        return-object
                      />
                    </v-col>

                    <v-col cols="8" sm="8" md="2" lg="2">
                      <z-btn
                        text="Avançar"
                        primary
                        color="primary"
                        @click="shouldShowRules"
                      />
                    </v-col>
                  </v-row>
                </div>
                <div v-if="showRules" class="rules">
                  <v-divider class="mb-3 mt-3"></v-divider>
                  <div class="rules__content">
                    <div class="rules__content__left">
                      <span> Regras </span>
                      <div class="rules__content__left__list">
                        <div
                          v-for="(item, i) in aggregationFieldsArray"
                          :key="i"
                          class="rules__content__left__list__line"
                        >
                          <z-select
                            v-if="dbConfig.type !== 'csv'"
                            v-model="item.keyItem"
                            label="Tabela adicional"
                            :items="choosenTable?.fields || []"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            itemText="name"
                            itemValue="name"
                            class="item"
                            :hideDetails="true"
                          />
                          <z-select
                            v-else
                            v-model="item.keyItem"
                            label="Tabela adicional"
                            :items="dataMapperObjectModular || []"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            itemText="item"
                            itemValue="item"
                            class="item"
                            :hideDetails="true"
                          />
                          <span class="item"> = </span>
                          <z-select
                            v-model="item.valueItem"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            :items="listConfigs"
                            label="Tabela primária"
                            class="item"
                            :hideDetails="true"
                          />
                          <figure class="action" @click="removeItemOnRules(item)">
                            <img src="/icons/minus-circle-icon.svg" />
                          </figure>
                        </div>
                      </div>
                      <div class="rules__content__left--action">
                        <z-btn
                          text="Adicionar nova regra"
                          icon="mdi-plus"
                          primary
                          color="primary"
                          :rounded="true"
                          @click="addRule"
                        />
                      </div>
                    </div>

                    <v-divider vertical class="hide"></v-divider>

                    <div class="rules__content__right">
                      <span> Tipo de junção</span>
                      <div class="rules__content__right__line">
                        <v-btn-toggle
                          v-model="joinType"
                          class="d-flex flex-wrap"
                          tile
                          color="#0273C3 "
                          group
                        >
                          <v-btn value="inner" elevation="1" large :style="zStyle">
                            <figure>
                              <img src="/icons/inner-icon.svg" />
                            </figure>
                            INNER
                          </v-btn>

                          <v-btn :style="zStyle" value="left" elevation="1" large>
                            <figure>
                              <img src="/icons/left-icon.svg" />
                            </figure>
                            LEFT
                          </v-btn>

                          <v-btn :style="zStyle" value="right" elevation="1" large>
                            <figure>
                              <img src="/icons/right-icon.svg" />
                            </figure>
                            RIGHT
                          </v-btn>

                          <v-btn :style="zStyle" value="outer" elevation="1" large>
                            <figure>
                              <img src="/icons/fullouter-icon.svg" />
                            </figure>
                            FULL OUTER
                          </v-btn>
                        </v-btn-toggle>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section v-if="stepperValue === 5">
            <div class="card-item-step">
              <div class="card-item-step__title">
                <span> Refresh </span>
              </div>
              <div class="card-item-step__content my-3">
                <div class="card-item-step__content__information">
                  <v-row>
                    <v-col cols="12" sm="12" md="3" lg="3">
                      <z-select
                        v-model="errorBehaviour"
                        label="Comportamento de erro"
                        :items="errorBehaviourItems"
                        :rules="[(v) => !!v || 'Campo obrigatório']"
                        :hideDetails="true"
                        itemText="name"
                        itemValue="value"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="3" lg="3">
                      <z-select
                        v-model="cron"
                        label="Frequência de atualização"
                        :items="dbConfig.type !== 'csv' ? frequencies : frequencies_csv"
                        :rules="[(v) => !!v || 'Campo obrigatório']"
                        :hideDetails="true"
                        itemText="name"
                        itemValue="value"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="
                      dbConfig.type !== 'csv' &&
                      cron &&
                      cron != 'not' &&
                      cron != 'not-api'
                    "
                  >
                    <v-col cols="12" sm="12" md="3" lg="3">
                      <z-autocomplete
                        v-model="controlColumn"
                        label="Campo de controle"
                        :items="choosenTable?.fields.length ? choosenTable.fields : []"
                        :hideDetails="true"
                        itemText="name"
                        itemValue="name"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-if="hasSelectedSkuId()">
                    <v-col cols="12" sm="12" md="3" lg="3">
                      <z-select
                        v-model="skuType"
                        label="Tipo do SKU"
                        :items="[
                          { name: 'token', value: 'token' },
                          { name: 'tópico', value: 'topic' },
                        ]"
                        :rules="[(v) => !!v || 'Campo obrigatório']"
                        :hideDetails="true"
                        itemText="name"
                        itemValue="value"
                      />
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                  <v-col cols="12" sm="12" md="3" lg="3">
                    <z-btn
                      text="Nome"
                      :rounded="true"
                      style="width: 100%"
                      @click="dialog = true"
                    />
                  </v-col>
                </v-row> -->
                </div>
              </div>
            </div>
          </section>
        </v-form>
        <div class="wrapper__card__stepper--actions">
          <z-btn
            v-if="stepperValue > 1"
            text="Voltar"
            color="primary"
            :rounded="true"
            @click="stepperValue > 1 ? stepperValue-- : ''"
          />
          <span v-else></span>
          <z-btn
            :text="stepperValue < 5 ? 'Prosseguir' : 'Finalizar'"
            primary
            color="primary"
            :rounded="true"
            @click="goToNext"
            :disabled="!canGoFoward"
            :is-loading="!canGoFoward"
          />
        </div>
      </div>
    </div>

    <v-dialog v-model="dialog" width="500">
      <v-form ref="formDialog" @submit.prevent="saveAndClose">
        <div class="wrapper__dialog">
          <div class="wrapper__dialog__title">
            <span>Insira o nome da configuração</span>
          </div>
          <div class="wrapper__dialog__form">
            <z-input
              v-model="configName"
              :hideDetails="true"
              placeholder="Nome"
              type="text"
            ></z-input>
          </div>

          <div class="wrapper__dialog--actions">
            <z-btn
              text="Cancelar"
              color="primary"
              :rounded="true"
              @click="cancelAndReset"
            />
            <z-btn
              text="Salvar"
              primary
              color="primary"
              :rounded="true"
              :is-loading="loadingModal"
              @click="saveAndClose"
            />
          </div>
        </div>
      </v-form>
    </v-dialog>

    <v-dialog v-model="phoneDialog" width="600">
      <div class="wrapper__phone-dialog">
        <div class="wrapper__phone-dialog__descriptions">
          <span>Configuração avançada do cliente de dados</span>
          <p>
            Você pode criar configurações mais detalhadas sobre os dados, como quando
            existir mais de uma fonte de dados para o campo escolhido.
          </p>
        </div>

        <div class="wrapper__phone-dialog__main-line mb-2">
          <div class="wrapper__phone-dialog__main-line__content item-columns">
            <div class="left">
              <z-select
                v-model="dataModel"
                label="Modelo de dados"
                :items="mainDataModels"
                itemText="name"
                itemValue="value"
                :hideDetails="true"
                class="item"
              />
              <z-select
                v-model="phoneDataType"
                label="Selecionar o tipo"
                :items="
                  dataModel === 'cod-pais'
                    ? selectTypeCountrie
                    : dataModel === 'cod-uf'
                    ? selectTypeState
                    : selectJustColumn
                "
                itemText="name"
                itemValue="value"
                :hideDetails="true"
                class="item"
              />
            </div>
            <div class="middle">
              <img src="/icons/arrow-right-icon.svg" />
            </div>
            <div class="right">
              <z-autocomplete
                v-model="phoneColumnDataType"
                :label="phoneLabelsType[phoneDataType]"
                :items="
                  phoneDataType === 'ddi'
                    ? countries
                    : phoneDataType === 'ddd'
                    ? dddList
                    : dbConfig.type !== 'csv' && choosenTable?.fields.length
                    ? choosenTable?.fields
                    : dbConfig.type == 'csv' && CSVColumns !== null
                    ? CSVColumns
                    : []
                "
                :itemText="
                  phoneDataType === 'ddi'
                    ? 'pais'
                    : phoneDataType === 'ddd'
                    ? 'location'
                    : dbConfig.type !== 'csv'
                    ? 'name'
                    : 'name'
                "
                :itemValue="
                  phoneDataType === 'ddi'
                    ? 'ddi'
                    : phoneDataType === 'ddd'
                    ? 'ddd'
                    : dbConfig.type !== 'csv' && choosenTable?.fields.length
                    ? 'name'
                    : dbConfig.type == 'csv' && CSVColumns !== null
                    ? 'name'
                    : 'name'
                "
                :hideDetails="true"
              />
            </div>

            <div class="action"></div>
          </div>
        </div>

        <div class="wrapper__phone-dialog__list">
          <div
            v-for="(item, i) in phoneCombinations"
            :key="i"
            class="wrapper__phone-dialog__list__item mb-2 item-columns"
          >
            <div class="left">
              <z-select
                v-model="item.data_model"
                label="Modelo de dados"
                :items="mainDataModels"
                itemText="name"
                itemValue="value"
                :hideDetails="true"
                class="item"
              />
              <z-select
                v-model="item.data_type"
                label="Selecionar o tipo"
                :items="
                  item.data_model === 'cod-pais'
                    ? selectTypeCountrie
                    : item.data_model === 'cod-uf'
                    ? selectTypeState
                    : selectJustColumn
                "
                itemText="name"
                itemValue="value"
                :hideDetails="true"
                :readonly="!isLastOption(item)"
                class="item"
              />
            </div>
            <div class="middle">
              <img src="/icons/arrow-right-icon.svg" />
            </div>

            <div class="right">
              <z-autocomplete
                v-model="item.table_column"
                :label="phoneLabelsType[item.data_type]"
                :items="
                  item.data_type === 'ddi'
                    ? countries
                    : item.data_type === 'ddd'
                    ? dddList
                    : dbConfig.type !== 'csv' && choosenTable?.fields.length
                    ? choosenTable?.fields
                    : dbConfig.type == 'csv' && CSVColumns !== null
                    ? CSVColumns
                    : []
                "
                :itemText="
                  item.data_type === 'ddi'
                    ? 'pais'
                    : item.data_type === 'ddd'
                    ? 'location'
                    : dbConfig.type !== 'csv'
                    ? 'name'
                    : 'name'
                "
                :itemValue="
                  item.data_type === 'ddi'
                    ? 'ddi'
                    : item.data_type === 'ddd'
                    ? 'ddd'
                    : dbConfig.type !== 'csv' && choosenTable?.fields.length
                    ? 'name'
                    : dbConfig.type == 'csv' && CSVColumns !== null
                    ? 'name'
                    : 'name'
                "
                :hideDetails="true"
                :readonly="!isLastOption(item)"
              />
            </div>
            <figure class="action">
              <img
                v-if="isLastOption(item)"
                src="/icons/minus-circle-icon.svg"
                @click="removePhoneCombination(item)"
              />
            </figure>
          </div>
        </div>

        <div class="wrapper__phone-dialog--add-combination d-flex justify-center">
          <z-btn
            icon="mdi-plus"
            text="adicionar mais uma combinação"
            primary
            color="primary"
            @click="addPhoneCombination"
          />
        </div>

        <div class="wrapper__phone-dialog--actions mt-2">
          <z-btn text="Cancelar" color="primary" @click="resetPhoneDialogAndClose()" />
          <z-btn
            text="Salvar configuração"
            primary
            color="primary"
            @click="phoneDialog = false"
          />
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ZStepperMobileVue from "@/components/Shared/ZStepperMobile.vue";
import ApiClient from "@/services/ApiClient";
import Papa from "papaparse";
import S3Service from "@/services/S3Service";
import {
  countries,
  dddList,
  selectTypeCountrie,
  selectTypeState,
  selectJustColumn,
} from "./mock";
export default {
  components: {
    ZStepperMobileVue,
  },
  data() {
    return {
      phoneCombinations: [],
      phoneDialog: false,
      phoneColumnDataType: "",
      countries,
      dddList,
      selectTypeCountrie,
      selectTypeState,
      selectJustColumn,
      dataModel: "",
      listToRemove: [],
      inferredDataTypes: {},
      trueElementsClone: [],

      phoneDataTypeList: {
        ddi: this.countries,
        ddd: this.dddList,
        columns: [],
      },
      phoneLabelsType: {
        ddd: "DDD",
        ddi: "DDI do país",
        column: "Coluna da tabela",
      },
      phoneDataType: "",
      mainDataModels: [
        {
          name: "Cód. País",
          value: "cod-pais",
        },
        {
          name: "Cód. UF",
          value: "cod-uf",
        },
        {
          name: "Cód. País + Cód. UF + Celular",
          value: "cod-pais-uf-phone",
        },
        {
          name: "DDD+Celular",
          value: "dddPhone",
        },
        {
          name: "Celular",
          value: "phone",
        },
      ],
      skuType: null,

      joinType: "inner",
      tables: [],
      loadingIntegration: false,
      loadingConnect: false,
      configurationList: [],
      aggregationFieldsArray: [{ auxId: "0000-0000-0000-0000-0000" }],
      dbConfig: {
        type: "",
        host: "",
        port: "",
        dbName: "",
        defaultSchema: "",
        user: "",
        pwd: "",
      },
      csvSeparator: null,
      csvLinebreak: null,
      csv_separators: [
        {
          name: ";",
          value: ";",
        },
        {
          name: ":",
          value: ":",
        },
        {
          name: ",",
          value: ",",
        },
      ],
      stepperValue: 1,
      steppers: [
        { id: 1, label: "Conexão", icon: "$check_rounded" },
        { id: 2, label: "Mapeamento de Dados", icon: "$check_rounded" },
        {
          id: 3,
          label: "Informações auxiliares (opcional)",
          icon: "$check_rounded",
        },
        { id: 4, label: "Agregação de Dados", icon: "$check_rounded" },
        { id: 5, label: "Refresh", icon: "$check_rounded" },
      ],
      step: 1,
      items: [],
      dateTimeFormats: [
        {
          format: "YYYY-MM-DDTHH:mm:ssZ",
          description: "ISO 8601 format",
          example: "2023-05-02T14:30:00Z",
        },
        {
          format: "DD/MM/YYYY HH:mm:ss",
          description: "UK/Canada format",
          example: "02/05/2023 14:30:00",
        },
        {
          format: "DD/MM/YYYY HH:mm",
          description: "UK/Canada format",
          example: "02/05/2023 14:30",
        },
        {
          format: "MM/DD/YYYY HH:mm:ss",
          description: "USA format",
          example: "05/02/2023 14:30:00",
        },
        {
          format: "MM/DD/YYYY HH:mm",
          description: "USA format",
          example: "05/02/2023 14:30",
        },
        {
          format: "YYYY-MM-DD HH:mm:ss",
          description: "Database format",
          example: "2023-05-02 14:30:00",
        },
        {
          format: "YYYY-MM-DD HH:mm:ss.SSS",
          description: "UK/Canada format",
          example: "2023-03-08 00:00:00.000000",
        },
      ],
      dateFormats: [
        {
          format: "YYYY-MM-DD",
          description: "ISO 8601 date format",
          example: "2023-05-02",
        },
        {
          format: "DD/MM/YYYY",
          description: "UK/Canada date format",
          example: "02/05/2023",
        },
        {
          format: "MM/DD/YYYY",
          description: "USA date format",
          example: "05/02/2023",
        },
        {
          format: "Do MMMM YYYY",
          description: "Long format",
          example: "2nd May 2023",
        },
      ],
      dataTypes: [
        {
          name: "Inteiro",
          value: "integer",
        },
        {
          name: "Texto",
          value: "string",
        },
        {
          name: "Data/hora",
          value: "datetime",
        },
        {
          name: "Data",
          value: "date",
        },
        {
          name: "Decimal",
          value: "decimal",
        },
        // {
        //   name: "Booleano",
        //   value: "bool",
        // },
      ],
      mapping: [
        "ID Único",
        "Data da compra",
        "Valor da compra",
        "Descrição (opcional)",
        "Categoria (opcional)",
      ],
      mapping2: ["ID da compra", "Valor da compra", "Data da compra"],
      text: "center",
      zStyle: {
        padding: "1.5rem .5rem",
        display: "flex",
        flexDirection: "column",
      },

      loadingModal: false,
      primaryColumn: "",
      controlColumn: "",
      cron: "",
      errorBehaviour: null,
      choosenConfiguration: null,
      showRules: false,
      dialog: false,
      configName: "",
      tableMock: {
        table: "DatabaseConfig",
        schema: "public",
        tableWithSchema: "public.DatabaseConfig",
        integrationId: "310fc9fb-21bb-4e53-bab3-dfb1037ecae3",
      },
      choosenTable: null,
      http: new ApiClient(),
      S3Service: new S3Service(),
      responseConnection: null,
      tablesModel: {},
      checkAllData: false,
      selectedCSVFile: null,
      s3Url: null,
      CSVColumns: null,
      canGoFoward: true,
      connections: [
        {
          name: "Upload de arquivo .csv",
          value: "csv",
        },
        {
          name: "Conexão Postgresql",
          value: "postgres",
        },
        // {
        //   name: "Conexão Redshift",
        //   value: "redshift",
        // },
        // {
        //   name: "Conexão Mysql",
        //   value: "mysql",
        // },
      ],
      errorBehaviourItems: [
        {
          name: "Ignorar Linha",
          value: "ignore",
        },
        {
          name: "Parar",
          value: "break",
        },
      ],
      frequencies_csv: [
        {
          name: "Não atualizar",
          value: "not",
        },
        {
          name: "Atualizar via Api",
          value: "not-api",
        },
      ],

      frequencies: [
        {
          name: "Não atualizar",
          value: "not",
        },
        {
          name: "Atualizar via Api",
          value: "not-api",
        },
        {
          name: "15 em 15 minutos",
          value: "*/15 * * * *",
        },

        {
          name: "30 em 30 minutos",
          value: "*/30 * * * *",
        },
        {
          name: "1 em 1 hora",
          value: "0 */1 * * *",
        },
        {
          name: "4 em 4 horas",
          value: "0 */4 * * *",
        },
        {
          name: "8 em 8 horas",
          value: "0 */8 * * *",
        },
        {
          name: "24 em 24 horas",
          value: "0 0 */1 * *",
        },
      ],
      dataMapperObjectFixed: {
        id: "",
        buy_data: "",
        buy_value: "",
        description: "",
        category: "",
        phone: "",
      },
      dataMapperSetObjects: {},
      dataMapperObjectModular: [],
      mapperEnum: {
        id: "ID Único *",
        buy_data: "Data da compra (opcional)",
        buy_value: "Valor da compra (opcional)",
        description: "Descrição (opcional)",
        category: "Categoria (opcional)",
        phone: "Telefone (Opcional)",
      },
    };
  },
  watch: {
    choosenConfiguration(nv) {
      if (nv) {
        this.aggregationFieldsArray = [];
        this.aggregationFieldsArray.push({ auxId: this.generateRandomId() });
      }
    },
    checkAllData(nv) {
      if (nv) this.checkAllOptions(nv);
      else this.checkAllOptions(false);
    },
    "dbConfig.type": {
      handler(nv) {
        if (nv === "csv") {
          this.steppers = [
            { id: 1, label: "Conexão", icon: "$check_rounded" },
            { id: 2, label: "Mapeamento de Dados", icon: "$check_rounded" },
            {
              id: 3,
              label: "Informações auxiliares (opcional)",
              icon: "$check_rounded",
            },
            { id: 4, label: "Agregação de Dados", icon: "$check_rounded" },
            { id: 5, label: "Refresh", icon: "$check_rounded" },
          ];
        } else {
          this.steppers = [
            { id: 1, label: "Conexão", icon: "$check_rounded" },
            { id: 2, label: "Mapeamento de Dados", icon: "$check_rounded" },
            {
              id: 3,
              label: "Informações auxiliares (opcional)",
              icon: "$check_rounded",
            },
            { id: 4, label: "Agregação de Dados", icon: "$check_rounded" },
            { id: 5, label: "Refresh", icon: "$check_rounded" },
          ];
        }
      },
      deep: true,
    },
    CSVColumns: {
      handler(nv) {
        if (nv) this.fillAlldataMapperObjectFixed();
      },
      deep: true,
    },
    stepperValue(nv) {
      if (nv > 3 && this.dataMapperObjectModular.length) {
        this.mergeObjectsToSendToApi();
      }
    },
  },
  computed: {
    listConfigs() {
      const t = JSON.parse(this.choosenConfiguration.fields);
      const list = Object.keys(t);
      return list || [];
    },
  },
  methods: {
    async changeTable() {
      console.log(this.choosenTable.integrationId);
      console.log(this.choosenTable.table);

      const res = await this.http.post("dataInfer", {
        data: {
          originTable: this.choosenTable.table,
          dbIntegrationId: this.choosenTable.integrationId,
        },
        type: "database",
      });
      console.log(res);

      this.inferredDataTypes = res;

      this.fillAlldataMapperObjectFixed();
      this.dataMapperSetObjects = {};
    },
    removeDeletedElements() {
      const list = [];

      // PERCORRER A LISTA DE ELEMENTOS SELECIONADOS
      for (const true_element of this.trueElementsClone) {
        // PERCORRER A LISTA DE POSSIVELS OBJETOS PARA REMOÇÃO
        for (const possible_item_to_remove of this.listToRemove) {
          //  VERIFICAR SE OS ITENS SÃO DIFERENTES

          if (true_element.destiny_name != possible_item_to_remove.name) {
            // SE FOR DIFERENTE, VERIFICAR SE NA MINHA LISTA ATUAL, JÁ EXISTE ALGUM ITEM COM O MESMO NOME, SE NÃO TIVER, INSERE
            if (!list.find((e) => e.name == possible_item_to_remove.name))
              list.push(possible_item_to_remove);
          }
        }
      }

      // CRIAÇÃO DE LISTA PARA ALTERAÇÃO, VERIFICANDO DE A MINHA LISTA DE ELEMENTOS TRUE MUDOU DESDE A ULTIMA VEZ
      const LIST_TO_ALTER = list.filter(
        (dado) => !this.trueElementsClone.some((obj) => obj.destiny_name == dado.name)
      );

      // CASO TENHA ELEMENTOS NA LISTA DE ALTERAÇÃO, FAZER A DEVIDA ALTERAÇÃO REMOVENDO DO SET E INSERINDO O NOVO VALOR
      if (LIST_TO_ALTER.length) {
        for (const data of LIST_TO_ALTER) {
          this.$delete(this.dataMapperSetObjects, data.name);

          this.dataMapperSetObjects = {
            ...this.dataMapperSetObjects,
            [data.key]: {
              [data.key]: data.key,
              type: data.type,
            },
          };
        }
      }

      return;
    },
    removePhoneCombination(item) {
      const FOUNDED = this.phoneCombinations.findIndex((d) => d.auxId == item.auxId);
      if (FOUNDED >= 0) this.phoneCombinations.splice(FOUNDED, 1);
    },
    addPhoneCombination() {
      this.phoneCombinations.push({
        auxId: this.generateRandomId(),
        data_model: "",
        data_type: "",
        table_column: "",
      });
    },
    resetPhoneDialogAndClose() {
      this.phoneCombinations = [];
      this.phoneDataType = "";
      this.phoneColumnDataType = "";
      this.phoneDialog = false;
    },
    isLastOption(item) {
      return (
        this.phoneCombinations[this.phoneCombinations.length - 1].auxId == item.auxId
      );
    },
    removeItemOnRules(item) {
      if (this.aggregationFieldsArray.length > 1) {
        const id = this.aggregationFieldsArray.findIndex(
          (data) => data.auxId == item.auxId
        );

        if (id) this.aggregationFieldsArray.splice(id, 1);
      }
    },

    generateRandomId() {
      const PART_1 = Math.floor(Math.random() * 245);
      const PART_2 = Date.now();
      return `${PART_1}${PART_2}`;
    },
    addRule() {
      // console.log(this.aggregationFields)
      this.aggregationFieldsArray.push({ auxId: this.generateRandomId() });
    },
    shouldShowRules() {
      (this.choosenTable || this.dbConfig.type === "csv") && this.choosenConfiguration
        ? (this.showRules = true)
        : "";
    },
    cancelAndReset() {
      this.dialog = false;
      this.configName = "";
    },

    async saveAndClose() {
      if (this.loadingModal) return;
      await this.mergeObjectsToSendToApi();
      await this.removeDeletedElements();
      this.addConfiguration();
    },

    mergeObjectsToSendToApi() {
      const TRUE_ELEMENTS = this.dataMapperObjectModular.filter(
        (item) => item.active == true
      );
      this.trueElementsClone = TRUE_ELEMENTS;
      for (const item of TRUE_ELEMENTS) {
        if (
          !Object.keys(this.dataMapperObjectFixed).find(
            (k) => k == item.name || k == item.item || item.destiny_name
          )
        ) {
          // let name = "";
          // if (item.destiny_name) name = item.destiny_name;
          // else item.name || item.item;
          // this.dataMapperSetObjects = {
          //   ...this.dataMapperSetObjects,
          //   ...this.dataMapperObjectFixed,
          //   [name]: {
          //     [name]: name,
          //     type: item.dataType,
          //   },
          // };
          let name = item.name || item.item;

          if (item.destiny_name) {
            name = item.destiny_name;
            let VALUE_TO_DELETE = item.item || item.name;

            // APAGAR A CHAVE QUE JÁ ESTÁ PREENCHIDA COM O NOME DE DESTINO NOVO NO OBJETO DE SETS
            this.$delete(this.dataMapperSetObjects, VALUE_TO_DELETE);

            this.dataMapperSetObjects = {
              ...this.dataMapperSetObjects,
              ...this.dataMapperObjectFixed,
              [name]: {
                [name]: item.name || item.item,
                type: item.dataType,
                format: item.format,
              },
            };
            this.listToRemove.push({
              name: name,
              type: item.dataType,
              key: item.name || item.item,
              [name]: {
                [name]: item.name || item.item,
                type: item.dataType,
                format: item.format,
              },
            });
          } else {
            this.dataMapperSetObjects = {
              ...this.dataMapperSetObjects,
              ...this.dataMapperObjectFixed,
              [name]: {
                [name]: item.name || item.item,
                type: item.dataType,
                format: item.format,
              },
            };
          }
        } else {
          // let name = "";
          // if (item.destiny_name) name = item.destiny_name;
          // else item.name || item.item;
          // this.dataMapperSetObjects[name] = {
          //   [name]: name,
          //   type: item.dataType,
          // };
          // this.dataMapperSetObjects = {
          //   ...this.dataMapperSetObjects,
          //   ...this.dataMapperObjectFixed,
          // };
          let name = item.name || item.item;

          if (item.destiny_name) {
            name = item.destiny_name;
            let VALUE_TO_DELETE = item.item || item.name;

            // APAGAR A CHAVE QUE JÁ ESTÁ PREENCHIDA COM O NOME DE DESTINO NOVO NO OBJETO DE SETS
            this.$delete(this.dataMapperSetObjects, VALUE_TO_DELETE);

            this.dataMapperSetObjects = {
              ...this.dataMapperSetObjects,
              ...this.dataMapperObjectFixed,
              [name]: {
                [name]: item.name || item.item,
                type: item.dataType,
                format: item.format,
              },
            };
            this.listToRemove.push({
              name: name,
              key: item.name || item.item,
              type: item.dataType,
              format: item.format,
              [name]: {
                [name]: item.name || item.item,
                type: item.dataType,
                format: item.format,
              },
            });
          } else {
            this.dataMapperSetObjects = {
              ...this.dataMapperSetObjects,
              ...this.dataMapperObjectFixed,
              [name]: {
                [name]: item.name || item.item,
                type: item.dataType,
                format: item.format,
              },
            };
          }
        }
      }

      if (!TRUE_ELEMENTS.length) {
        this.dataMapperSetObjects = { ...this.dataMapperObjectFixed };
      }

      return;
    },

    goToNext() {
      if (this.$refs.form.validate()) {
        this.stepperValue < 5 ? this.stepperValue++ : (this.dialog = true);
      }
    },

    goToNextZMode() {
      // if (this.stepperValue > value) this.stepperValue = value;
      // if (this.$refs.form.validate()) this.stepperValue = value;
    },

    fillAlldataMapperObjectFixed() {
      // PREENCHER O MAPPER COM TODOS OS DADOS INICIAIS, NO FIM, SE ALGUM VALOR ESTIVER VAZIO, REMOVER DO OBJKETP
      // RESET DE ARRAY TODA VEZ QUE AS INFORMAÇÕES MUDAREM
      this.dataMapperObjectModular = [];
      if (this.dbConfig.type !== "csv") {
        for (let item of this.choosenTable.fields) {
          if (!Object.keys(this.dataMapperObjectFixed).find((x) => x == item.name)) {
            this.dataMapperObjectModular.push({
              ...item,
              destiny_name: "",
              dataType:
                this.inferredDataTypes[item.name] &&
                this.inferredDataTypes[item.name].type
                  ? this.inferredDataTypes[item.name].type
                  : "string",
              format:
                this.inferredDataTypes[item.name] &&
                this.inferredDataTypes[item.name].format
                  ? this.inferredDataTypes[item.name].format
                  : null,
              active: item.active || false,
            });
          }
        }
      } else {
        for (let item of this.CSVColumns) {
          if (!Object.keys(this.dataMapperObjectFixed).find((x) => x == item)) {
            this.dataMapperObjectModular.push({
              item,
              destiny_name: "",
              dataType:
                this.inferredDataTypes[item] && this.inferredDataTypes[item].type
                  ? this.inferredDataTypes[item].type
                  : "string",
              format:
                this.inferredDataTypes[item] && this.inferredDataTypes[item].format
                  ? this.inferredDataTypes[item].format
                  : null,
              active: item.active || false,
            });
          }
        }
      }
    },
    hasSelectedSkuId() {
      return this.dataMapperObjectFixed.sdkId;
    },

    addOrRemoveKeyOnMapper(event, item) {
      // CASO SEJA SELECIONADO INSERIR DADOS
      if (event) {
        let hasKey = false;

        // PERCORRER O OBJETO ESCOLHIDO AFIM DE IDENTIFICAR SE JÁ EXISTE ALGUMA KEY COM O NOME ESCOLHIDO
        if (this.dbConfig.type !== "csv") {
          for (const d of this.choosenTable.fields) {
            hasKey = Object.keys(d).some((x) => x == item.name);
          }
        } else {
          hasKey = this.CSVColumns.some((x) => x == item);
        }

        // SE HOUVER A KEY, EXIBIR MENSAGEM DE ALERTA FALANDO QUE KEY JÁ EXISTE
        if (hasKey) {
          this.$toast.warning("Key já cadastrada, por favor, insira outro nome");
          return;
        }

        // CASO NÃO HOUVER, INSERIR A KEY NO OBJETO DINÂMICO (dataMapperObjectFixed, ESSE IRÁ SER O FIELDS COM TODOS OS CAMPOS SELECIONADOS)
        if (this.dbConfig.type !== "csv") {
          if (!item.destiny_name) {
            let NEW_OBJ = {};
            NEW_OBJ[item.item] = item.name;
            NEW_OBJ.type = item.dataType;
            NEW_OBJ.format = item.format;

            this.$set(this.dataMapperSetObjects, item.name, NEW_OBJ);
          } else {
            let NEW_OBJ = {};
            NEW_OBJ[item.destiny_name] = item.destiny_name;
            NEW_OBJ.type = item.dataType;
            NEW_OBJ.format = item.format;

            this.$set(this.dataMapperSetObjects, item.destiny_name, NEW_OBJ);
          }
        } else {
          if (!item.destiny_name) {
            let NEW_OBJ = {};
            NEW_OBJ[item.item] = item.item;
            NEW_OBJ.type = item.dataType;
            NEW_OBJ.format = item.format;

            this.$set(this.dataMapperSetObjects, item.item, NEW_OBJ);
          } else {
            let NEW_OBJ = {};
            NEW_OBJ[item.destiny_name] = item.destiny_name;
            NEW_OBJ.type = item.dataType;
            NEW_OBJ.format = item.format;

            this.$set(this.dataMapperSetObjects, item.destiny_name, NEW_OBJ);
          }
        }
      } else {
        // APAGAR NO OBJETO MAPPER A KEY RECEBIDA
        delete this.dataMapperSetObjects[""];
        this.dbConfig.type !== "csv"
          ? this.$delete(this.dataMapperSetObjects, item.name)
          : this.$delete(this.dataMapperSetObjects, item.item);

        if (item.destiny_name) this.$delete(this.dataMapperSetObjects, item.destiny_name);
      }
    },

    getCampName(item) {
      return this.mapperEnum[item];
    },

    checkAllOptions(value) {
      // this.dataMapperObjectModular.forEach((item) => (item.active = value));
      this.dataMapperObjectModular.forEach((item) => {
        item.active = value;
        this.addOrRemoveKeyOnMapper(value, item);
      });
    },

    async retryCsvUpload() {
      const $context = this;
      var reader = new FileReader();
      reader.readAsDataURL(this.selectedCSVFile);
      reader.onload = async () => {
        const arr = $context.Uint8ArrayFromBase64(
          reader.result.replace("data:text/csv;base64,", "")
        );

        this.s3Url = await this.S3Service.singleUpload(this.selectedCSVFile, "CSVs", arr);
      };
    },

    async addConfiguration() {
      const SET_TRANSFORMEDS = {};

      const SET_AGGREGATION_FIELDS = {};

      if (this.dbConfig.type === "csv" && !this.s3Url) {
        this.$toast.warning("Csv ainda subindo, aguarde!");
      }

      // for (const [key, value] of Object.entries(this.dataMapperSetObjects)) {
      //   if (key) {
      //     SET_TRANSFORMEDS[key] = {
      //       origins: value[key] ? [`${value[key]}`] : value ? [`${value}`] : [],
      //       type: value.type ? value.type : "string",
      //     };
      //   }
      // }

      for (const [key, value] of Object.entries(this.dataMapperSetObjects)) {
        if (key) {
          if (key != "phone") {
            SET_TRANSFORMEDS[key] = {
              origins: value[key] ? [`${value[key]}`] : value ? [`${value}`] : [],
              type: value.type ? value.type : "string",
              format: value.format,
            };
          } else {
            SET_TRANSFORMEDS[key] = {
              origins: [],
              type: value.type ? value.type : "string",
            };

            if (this.phoneColumnDataType) {
              if (this.phoneDataType != "column")
                SET_TRANSFORMEDS[key].origins.push(`'${this.phoneColumnDataType}'`);
              else SET_TRANSFORMEDS[key].origins.push(`${this.phoneColumnDataType}`);
            }

            for (const phoneItem of this.phoneCombinations) {
              if (phoneItem.data_type != "column")
                SET_TRANSFORMEDS[key].origins.push(`'${phoneItem.table_column}'`);
              else SET_TRANSFORMEDS[key].origins.push(`${phoneItem.table_column}`);
            }
          }
        }
      }

      for (const item of this.aggregationFieldsArray) {
        if (item.keyItem) SET_AGGREGATION_FIELDS[item.keyItem] = item.valueItem;
      }

      try {
        this.loadingModal = true;

        const res = await this.http.post("conversionDataConfiguration/addConfiguration", {
          data: {
            dbIntegrationId: this.choosenTable ? this.choosenTable.integrationId : null,
            typeConfig: this.dbConfig.type,
            cron: this.cron === "not" || this.cron === "not-api" ? null : this.cron,
            errorBehaviour: this.errorBehaviour,
            apiEnabled: this.cron === "not-api",
            table: this.choosenTable ? this.choosenTable.table : null,
            csvSeparator: this.csvSeparator,
            csvLinebreak: this.csvLinebreak,
            name: this.configName,
            fields: { ...SET_TRANSFORMEDS },
            aggregationFields: { ...SET_AGGREGATION_FIELDS },
            aggregatedDbConfigId: this.choosenConfiguration.id || null,
            aggregationType: this.joinType,
            skuType: this.hasSelectedSkuId() ? this.skuType : null,
            controlColumn: this.controlColumn,
            csvUrl: this.dbConfig.type === "csv" ? this.s3Url : null,
          },
        });

        this.loadingModal = false;

        if (res.id || res == "OK") this.$router.push({ name: "conversionDataIndexPage" });
      } catch (error) {
        this.$toast.error("Erro ao realizar requisição!");
        this.loadingModal = false;
      }
    },

    async integrationTest() {
      if (this.$refs.form.validate()) {
        try {
          this.loadingIntegration = true;
          const req = await this.http.post("dbIntegration/testIntegration", {
            dbConfig: { ...this.dbConfig },
          });

          if (req && req.ok) {
            this.$toast.success("Integração realizada com sucesso!");
          } else {
            this.$toast.warning("Falha ao realizar a integração");
          }

          this.loadingIntegration = false;
        } catch (error) {
          this.loadingIntegration = false;
          this.$toast.error("Erro ao realizar teste de integração");
        }
      }
    },

    async openFilePicker() {
      this.$refs.file.click();
    },

    Uint8ArrayFromBase64(base64) {
      return Uint8Array.from(window.atob(base64), (v) => v.charCodeAt(0));
    },

    async previewFiles(event) {
      const $context = this;
      this.selectedCSVFile = event.target.files[0];
      const FILE_SIZE_IN_MB = this.selectedCSVFile.size / (1024 * 1024);
      this.canGoFoward = false;

      if (FILE_SIZE_IN_MB > 100) {
        this.$toast.warning("O tamanho do arquivo deve ser menor que 100mb");
        this.selectedCSVFile = null;
        return;
      }

      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async () => {
        const arr = $context.Uint8ArrayFromBase64(
          reader.result.replace("data:text/csv;base64,", "")
        );

        this.sendFile(arr);

        let readLines = 0;
        let rows = [];
        Papa.parse(this.selectedCSVFile, {
          header: true,
          step: function (results, parser) {
            rows.push(results.data);
            if (readLines < 6) {
              readLines++;
            } else {
              parser.abort();
            }
          },
          complete: async function (results) {
            console.log(results);

            console.log(rows);

            const res = await $context.http.post("dataInfer", rows);

            $context.inferredDataTypes = res;

            $context.CSVColumns = results.meta.fields.filter((x) => x);
            $context.csvSeparator = results.meta.delimiter;
            $context.csvLinebreak = results.meta.linebreak;
          },
        });
      };
      this.getConfigurationList();
    },

    async sendFile(arr) {
      try {
        this.s3Url = await this.S3Service.singleUpload(this.selectedCSVFile, "CSVs", arr);
        this.$toast.success("Arquivo CSV salvo com sucesso!");
      } catch (e) {
        this.$toast.error("Erro ao salvar arquivo CSV!");
      }
      this.canGoFoward = true;
    },

    async conect() {
      if (this.$refs.form.validate()) {
        try {
          this.loadingConnect = true;
          const req = await this.http.post("dbIntegration/addIntegration", {
            dbConfig: { ...this.dbConfig },
          });
          this.responseConnection = req;
          this.tablesModel.schema = "public";
          this.tablesModel.id = req.id;
          this.getTables();
          this.loadingConnect = false;
          // this.$toast.success("Conectado com sucesso!");
          this.getConfigurationList();
          // this.stepperValue++;
        } catch (e) {
          this.loadingConnect = false;
          this.$toast.error("Erro ao realizar requisição!");
        }
      }
    },

    async getConfigurationList() {
      try {
        const req = await this.http.get("dbConfiguration/list?status=updated&sort=created&order=DESC");
        this.configurationList = req;
      } catch (error) {
        this.$toast.error("Erro ao buscar lista de configurações");
      }
    },

    async getTables() {
      try {
        const req = await this.http.post("dbIntegration/getTables", this.tablesModel);
        this.tables = req;
        // console.log(this.tables);
      } catch (error) {
        this.$toast.error("Erro ao buscar tabelas!");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.wrapper {
  color: $z-black-1;
  // padding: $z-s-1 $z-s-2;
  padding: $z-s-1;
  height: calc(100vh - 48px);
  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: auto;
    @include trackScrollBar;

    &__stepper {
      &__zstepper {
        margin-bottom: $z-s-1;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        .show-on-mobile {
          display: none;
        }

        @media (max-width: 750px) {
          .hide-on-mobile {
            display: none;
          }
          .show-on-mobile {
            display: block;
          }
        }
      }
      .form-content {
        margin-bottom: $z-s-1;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        padding: 0.5rem $z-s-1;
        height: 60vh;
        overflow-y: auto;
        @include trackScrollBar;
        &__title {
          margin-bottom: 0.5rem;
          span {
            font-weight: 700;
            font-size: 0.9em;
            line-height: 15px;
            letter-spacing: 0.15px;
            text-transform: uppercase;
          }
        }
        &__header {
          max-width: 350px;
        }
        &__body {
          &__elements {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 1rem;

            &__left {
              flex: 2 1 600px;
            }
            &__right {
              flex: 1 1 300px;
            }

            & > div {
              // flex: 1 1 500px;

              .title {
                span {
                  font-weight: 400;
                  font-size: 0.85em;
                  line-height: 15px;
                }
              }

              .list {
                &__item {
                  margin-bottom: 1rem;
                  display: flex;
                  align-items: center;
                  gap: 1rem 2rem;
                  flex-wrap: wrap;

                  &--action {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 0.5rem;
                    flex-wrap: wrap;
                  }

                  &__input {
                    width: 70%;

                    @media (max-width: 750px) {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .card-item-step {
        margin-bottom: $z-s-1;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        // padding: $z-s-1;
        padding: 0.5rem $z-s-1;
        // border: 5px solid red;
        height: 60vh;
        overflow-y: auto;
        @include trackScrollBar;
        &__title {
          span {
            font-weight: 700;
            font-size: 0.9em;
            line-height: 15px;
            letter-spacing: 0.15px;
            text-transform: uppercase;
          }
        }
        &__content {
          &__information {
            .actions {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              flex-wrap: wrap;
              gap: 0.5rem;

              @media (max-width: 955px) {
                justify-content: center;
              }
            }
            span {
              font-weight: 400;
              font-size: 0.85em;
              line-height: 15px;
            }
          }
        }
        @media (max-width: 1169px) {
          height: auto;
        }
      }
      .step-2 {
        .rules {
          &__content {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: $z-s-1;

            &__left,
            &__right {
              flex: 1 1 300px;

              span {
                display: block;
                margin-bottom: $z-s-1;
              }
            }

            &__left {
              &__list {
                &__line {
                  display: flex;
                  gap: 0.8rem;
                  max-height: 10vh;

                  span {
                    color: #666666;
                    font-weight: 700;
                    font-size: 1.5em;
                  }

                  .item {
                    &:not(:nth-child(2)) {
                      flex: 1 1 0;
                    }
                  }

                  figure {
                    cursor: pointer;
                    width: 30px;
                    height: 30px;
                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: contain;
                    }
                  }

                  // @media (max-width: 700px) {
                  //   flex-wrap: wrap;
                  //   max-height: 100%;
                  //   align-items: center;
                  //   justify-content: center;
                  //   .item {
                  //     flex: 1 1 400px;
                  //     text-align: center;
                  //   }
                  // }
                  @media (max-width: 700px) {
                    padding: 1rem;
                    border: 1px solid #c9d6e0;
                    background: #fafbfc;
                    border-radius: 10px;
                    gap: 0;
                    flex-wrap: wrap;
                    max-height: 100%;
                    .item {
                      flex: 1 1 500px;
                      text-align: center;
                    }
                    figure {
                      margin-top: 0.5rem;
                      width: 100%;
                    }

                    margin-bottom: 1rem;
                  }
                }
              }
              &--action {
                text-align: right;
                @media (max-width: 700px) {
                  text-align: center;
                }
              }
            }

            @media (max-width: 962px) {
              .hide {
                display: none;
              }
              &__right {
                order: 1;
              }
              &__left {
                order: 2;
              }
            }
          }
        }
      }
      .step-3,
      .step-4 {
        .table {
          margin-top: $z-s-2;
          max-width: 1000px;
          &__header,
          &__body {
            &__line {
              display: flex;
              align-items: center;
              gap: $z-s-1;
              margin-bottom: 0.5rem;
              & > div {
                flex: 1 1 300px;
                width: 100%;
                .text-header {
                  font-weight: 700;
                  font-size: 0.85em;
                  line-height: 15px;
                  letter-spacing: 0.15px;
                  text-transform: uppercase;
                  display: flex;
                  align-items: center;
                }
                &:nth-child(2) {
                  flex: 3 1 500px;
                }
              }
              @media (max-width: 750px) {
                & > div {
                  width: auto;
                  span {
                    font-size: 0.85rem;
                    font-weight: 500;
                  }
                  &:nth-child(2) {
                    flex: 1 1 300px !important;
                  }
                }
                .hide {
                  display: none;
                }
              }
            }
          }
          .flex-line {
            span {
              font-size: 0.9rem;
            }
          }
          @media (max-width: 750px) {
            .flex-line {
              padding-bottom: 0.6rem;
              &:not(:last-child) {
                border-bottom: 1px solid #c9d6e0;
                margin-bottom: 1rem;
              }

              & > div {
                &:nth-child(1) {
                  flex: 0 0 auto;
                }
                &:nth-child(2) {
                }
                &:nth-child(3) {
                  flex: 1 1 200px;
                }
              }
            }
          }
          &--action {
            text-align: right;
            max-width: 80%;
            padding: $z-s-1 0;
          }
        }
      }
      &--actions {
        // text-align: right;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
  }
  &__dialog {
    background: white;
    padding: $z-s-1;

    &__title {
      span {
        font-size: 1.2em;
        font-weight: 600;
      }
    }

    &__form {
      margin: $z-s-1 0;
    }

    &--actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
  }
  &__phone-dialog {
    background: white;
    padding: $z-s-1;
    border-radius: 15px;

    &__descriptions {
      span {
        font-size: 1.2rem;
        font-weight: 600;
      }
      p {
        font-weight: 500;
      }
    }
    .item-columns {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem 0.2rem;
      .left,
      .right {
        width: 100%;
      }
      .middle {
        img {
          width: 33px;
          height: 33px;
        }
      }
      .action {
        min-width: 27px;
        height: 27px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          cursor: pointer;
        }
      }
    }
    &__main-line {
      &__content {
        .left {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          .item {
            flex: 1 1 0;
            width: 0;
          }
        }

        @media (max-width: 750px) {
          flex-wrap: wrap;
          .middle {
            display: none;
          }
        }
      }
    }
    &__list {
      .left {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .item {
          flex: 1 1 0;
          width: 0;
        }
      }
    }
    &--actions {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem 0.5rem;
    }

    @media (max-width: 750px) {
      padding: $z-s-1 1.5rem;
      &__descriptions {
        span {
          font-size: 0.95rem;
        }
        p {
          font-size: 0.85rem;
        }
      }
    }
  }
  @media (max-width: 650px) {
    padding: $z-s-1;
  }
}
</style>
